// App.js
import React from 'react';
import './App.css'; // Import your CSS file here
import { useState, useEffect } from 'react';
import { getDeals } from '../../hooks/useBackendUpdate';

const App = () => {

  const [deals, setDeals] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState(deals);
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);

  useEffect(() => {
    const fetchDeals = async () => {
      const deals = await getDeals();
      setDeals(deals.map((deal, index) => ({ ...deal, id: index })));
      setFilteredDeals(deals.map((deal, index) => ({ ...deal, id: index })));
    };
    fetchDeals();
  }, []);

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredDeals(deals);
    } else {
      const lowerCaseSearchTerm = searchTerm?.toLowerCase();
      console.log('lowerCaseSearchTerm: ', lowerCaseSearchTerm);
      if (lowerCaseSearchTerm != '' && lowerCaseSearchTerm != null) {
        setFilteredDeals(deals.filter(deal =>
          deal.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
          deal.merchant?.toLowerCase().includes(lowerCaseSearchTerm) ||
          deal.price?.toLowerCase().includes(lowerCaseSearchTerm)
        ));
      }
    }
  };

  console.log('deals: ', deals);
  return (
    <div className="app-container">
      <Header onSearch={handleSearch} />
      <MerchantFilter deals={deals} setFilteredDeals={setFilteredDeals} />
      <MainContent deals={filteredDeals} />
      <Sidebar />
      <Footer />
    </div>
  );
};


const Header = ({ onSearch }) => (
  <header className="header">
    <h1>The best deals in...</h1>
    <SearchBar onSearch={onSearch} />
  </header>
);

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm === '' && !isFocused) {
        return; // Do not trigger onSearch when the search bar is not focused and the search term is empty
      }
      onSearch(searchTerm);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, onSearch, isFocused]);

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  );
};


const MainContent = ({ deals }) => {
  return (
    <div className="main-content">
      {deals.map(deal => (
        <DealCard key={deal.id} deal={deal} />
      ))}
    </div>
  );
};

const parsePrice = (priceStr) => {
  const matches = priceStr?.match(/\$([0-9,.]+)/);
  return matches ? parseFloat(matches[1]?.replace(/,/g, '')) : null;
};

const DealCard = ({ deal }) => {
  let gradientColor = 'lightgray'; // Default color for missing prices

  try {
    const priceNum = parsePrice(deal.price);
    const OGPriceNum = parsePrice(deal.OGPrice);
    if (priceNum && OGPriceNum) {
      const discountPercentage = Math.round((1 - priceNum / OGPriceNum) * 100);
      gradientColor = `linear-gradient(90deg, green, green ${discountPercentage}%, amber ${discountPercentage}%, amber 100%)`;
    }
  } catch (err) {
    console.log('Error parsing price: ', err);
  }

  // console.log('gradientColor: ', gradientColor)

  return (
    <div className="deal-card">
      <a href={deal.finalUrl} target="_blank" rel="noopener noreferrer" className="deal-image-container">
        <img src={deal.imageUrl || 'https://slickdeals.net/attachment/4/2/8/1/6/200x200/14539315.thumb'} alt={deal.name} className="deal-image" />
      </a>
      <div className="deal-info">
        <a href={deal.finalUrl} target="_blank" rel="noopener noreferrer" className="deal-name-link">
          <h2 className="deal-name">{deal.name}</h2>
        </a>
        <div className="price-container">
          <a href={deal.finalUrl} target="_blank" rel="noopener noreferrer" className="deal-price-link">
            <p className="deal-price">{deal.price || ''}</p>
          </a>
          <a href={deal.finalUrl} target="_blank" rel="noopener noreferrer" className="original-price-link">
            <p className="original-price">{deal.OGPrice || ''}</p>
          </a>
        </div>
        <p className="deal-merchant">{deal.merchant || ''}</p>
      </div>
      {/* inline set gradientColor for deal indicator */}
      {/* <div className="deal-indicator" style={{ background: gradientColor, height: "10px", width: "100%", marginTop: "10px" }}></div> */}
    </div>
  );
};

const Sidebar = () => (
  <aside className="sidebar">
    {/* Map through your blog post links and create a link for each post */}
  </aside>
);

const Footer = () => (
  <footer className="footer">
    <p>The best deals - aggregated from all over the internet</p>
  </footer>
);

const MerchantFilter = ({ deals, setFilteredDeals }) => {
  const [selectedMerchant, setSelectedMerchant] = useState('All');

  // Calculate the top merchants
  const merchantCounts = deals.reduce((acc, deal) => {
    acc[deal.merchant] = (acc[deal.merchant] || 0) + 1;
    return acc;
  }, {});

  const topMerchants = Object.entries(merchantCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(entry => entry[0]);

  // Handle merchant selection
  const handleMerchantClick = (merchant) => {
    setSelectedMerchant(merchant);
    if (merchant === 'All') {
      setFilteredDeals(deals);
    } else {
      setFilteredDeals(deals.filter(deal => deal.merchant === merchant));
    }
  };

  return (
    <div className="merchant-filter">
      {/* Add 'All' pill at the beginning */}
      <button
        onClick={() => handleMerchantClick('All')}
        className={selectedMerchant === 'All' ? 'selected' : ''}
      >
        All
      </button>
      {/* Existing code to display top merchants */}
      {topMerchants.map(merchant => (
        merchant &&
        <button
          key={merchant}
          onClick={() => handleMerchantClick(merchant)}
          className={selectedMerchant === merchant ? 'selected' : ''}
        >
          {merchant}
        </button>
      ))}
    </div>
  );
};


export default App;
