import { useState, useEffect } from 'react';

function useBackendUpdate(initialState, data) {
  const [kbState, setKbState] = useState(initialState);

  useEffect(() => {
    const updateBackend = async () => {
      try {
        console.log('kbState: ', kbState);
        const response = await fetch('http://localhost:5001/knowledge-check-blocks/state', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(kbState),
        });

        if (!response.ok) {
          throw new Error('Failed to update backend');
        }
      } catch (error) {
        console.error('Error updating backend:', error);
      }
    };

    updateBackend();
  }, [kbState, data.kb_id]);

  return [kbState, setKbState];
}


const getDeals = () => {
  return ([
    {
      "link": "https://sovrn.co/1a9x1pv",
      "merchant": "Amazon",
      "timestamp": 1700645877985,
      "price": "$38",
      "OGPrice": "$70",
      "upvotes": "43",
      "name": "Oranlife Food Containers, 50 Pack Disposable Meal Storage Lunch Containers",
      "imageUrl": "https://m.media-amazon.com/images/I/71STdXVdNeL._AC_SX679_PIbundle-50,TopRight,0,0_SH20_.jpg",
      "finalUrl": "https://sovrn.co/1a9x1pv"
    },
    {
      "link": "https://sovrn.co/1a9x1pv",
      "merchant": "Amazon",
      "timestamp": 1700645877985,
      "price": "$1.67",
      "OGPrice": "$25",
      "upvotes": "0",
      "name": "Oranlife Food Containers, 50 Pack Disposable Meal Storage Lunch Containers",
      "imageUrl": "https://m.media-amazon.com/images/I/71STdXVdNeL._AC_SX679_PIbundle-50,TopRight,0,0_SH20_.jpg",
      "finalUrl": "https://sovrn.co/1a9x1pv"
    },
    {
      "link": "https://slickdeals.net/f/17080717-carlinkit-5-0-cpc200-2air-replace-your-wired-carplay-android-auto-to-wireless-37?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Mini In The Box",
      "timestamp": 1700645877985,
      "price": "$37",
      "OGPrice": "$180",
      "upvotes": "14",
      "name": "Carlinkit 5.0 CPC200-2AIR - Replace your wired Carplay + Android Auto to wireless! $37",
      "finalUrl": "https://www.miniinthebox.com/en/p/carlinkit-5-0-carplay-android-auto-wireless-adapter-portable-dongle-for-oem-car-radio-with-wired-carplay-android-auto-2023-newest-cpc200-2air_p9543179.html?prm=2.5.1.1&utm_campaign=10451&utm_content=Slickdeals_LLC&utm_source=gan&utm_medium=affiliate&litb_from=affiliate_gan&clickid=&irgwc=1"
    },
    {
      "link": "https://slickdeals.net/f/17071030-tcl-98-class-s5-s-class-led-4k-uhd-98s550g-best-buy-2499-99?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Amazon",
      "timestamp": 1700645877985,
      "price": "$2498",
      "OGPrice": "$5,000",
      "upvotes": "24",
      "name": "98\" TCL 98S550G S5 Class 4K LED HDR Smart Google TV (2023 Model)",
      "finalUrl": "https://www.amazon.com/gp/product/B0CDNZTDWN/ref=ox_sc_act_title_1?tag=slickdeals09-20&ascsubtag=d3a228f0891a11ee814f162af5eba7fc0INT&smid=ATVPDKIKX0DER",
      "finalWithReferralTag": "https://www.amazon.com/gp/product/B0CDNZTDWN?tag=mytag123",
      "imageUrl": "https://m.media-amazon.com/images/I/81d8DOZ0ixL.__AC_SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
      "link": "https://slickdeals.net/f/17055952-amazon-select-amex-rewards-cardholders-pay-w-points-get-up-to-50-off-max-80-off?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Amazon",
      "timestamp": 1700645877985,
      "price": "50% Off",
      "OGPrice": null,
      "upvotes": "100",
      "name": "Amazon: Select Amex Rewards Cardholders: Pay w/ Points, Get Up To",
      "finalUrl": "https://www.amazon.com/b?tag=slickdeals09-20&ascsubtag=d37c3ae6891a11ee96b59a140b1569d60INT&node=20906611011",
      "finalWithReferralTag": "https://www.amazon.com/b?tag=mytag123"
    },
    {
      "link": "https://slickdeals.net/f/17078275-philips-norelco-oneblade-360-pro-face-body-hybrid-electric-trimmer-and-shaver-50-99-free-shipping?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Best Buy",
      "timestamp": 1700645877985,
      "price": "$50.99",
      "OGPrice": "$90",
      "upvotes": "1",
      "name": "Philips Norelco - OneBlade 360, Pro Face & Body, Hybrid Electric Trimmer and Shaver - $50.99 + Free Shipping",
      "finalUrl": "https://www.bestbuy.com/site/philips-norelco-oneblade-360-pro-face-body-hybrid-electric-trimmer-and-shaver-qp6551-70-chrome/6530126.p?acampID=0&irgwc=1&loc=Slickdeals_LLC&mpid=10451&ref=198&skuId=6530126&irclickid="
    },
    {
      "link": "https://slickdeals.net/f/17063575-smart-and-final-buy-100-select-gift-cards-get-15-coupon-off-next-purchase?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Smart and Final",
      "timestamp": 1700645877985,
      "price": null,
      "OGPrice": null,
      "upvotes": "4",
      "name": "Smart and Final: Buy $100 select gift cards, get $15 coupon off next purchase",
      "finalUrl": "https://www.smartandfinal.com/sites/default/files/upload/wk46_preholiday_gift_card_participating_items.pdf?298"
    },
    {
      "link": "https://slickdeals.net/f/17077327-limited-time-deal-philips-1200-series-fully-automatic-espresso-machine-classic-milk-frother-2-coffee-varieties-intuitive-touch-display-black-ep1220-04-329-99?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "",
      "timestamp": 1700645877985,
      "price": "$329.99",
      "OGPrice": null,
      "upvotes": "9",
      "name": "Limited-time deal: Philips 1200 Series Fully Automatic Espresso Machine - Classic Milk Frother, 2 Coffee Varieties , Intuitive Touch Display, Black, (EP1220/04) - $329.99",
      "finalUrl": "https://www.amazon.com/dp/B07Z2X25SY?tag=slickdeals09-20&ascsubtag=d3667eea891a11ee972a0ab13a408f6d0INT&th=1"
    },
    {
      "link": "https://slickdeals.net/f/16002898-select-amex-cardholders-purchase-300-delta-gift-card-get-6-000-mr-points-valid-one-time-only?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Amex Offers",
      "timestamp": 1700645877985,
      "price": "6,000 MR Points",
      "OGPrice": null,
      "upvotes": "93",
      "name": "Select Amex Cardholders: Purchase $300 Delta Gift Card, Get",
      "finalUrl": "https://www.americanexpress.com/en-us/benefits/offers/?sharedid=14011810018&subid1=d3790420891a11ee9cd2f2b6c0bebaaf0INT"
    },
    {
      "link": "https://slickdeals.net/f/17080423-adidas-women-s-duramo-sl-running-shoes-21-cloudfoam-pure-shoes-olive-22-50-runfalcon-3-running-shoes-25-50-more-w-shop-premium-outlets-app-fs?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Shop Premium Outlets",
      "timestamp": 1700645877985,
      "price": "$21",
      "OGPrice": "$70",
      "upvotes": "4",
      "name": "adidas Women's Duramo Sl Running Shoes $21, Cloudfoam Pure Shoes (olive) $22.50, Runfalcon 3 Running Shoes $25.50, More w/ Shop Premium Outlets App + FS",
      "finalUrl": "https://slickdeals.net/?sdtrk=jfy&prop=diavail-false%7Cdincp-0%7Cdinpd-0%7Cdipgavail-false%7Crcmid-0a02979deaffc1c549ca5ef912901567&afsrc=1&trd=See%20Deal&sdtid=17080423&tid=17080423&pv=dd848200891a11eea69b9277d926bb46&au=93d7761f53c84b9592ea914f265cdd36"
    },
    {
      "link": "https://slickdeals.net/f/17074795-lg-14-gram-style-14-2-8k-oled-120hz-i7-1360p-16gb-lpddr5-512gb-ssd-1099?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "B&H Photo Video",
      "timestamp": 1700645877985,
      "price": "$1,099",
      "OGPrice": "$1,499",
      "upvotes": "1",
      "name": "LG 14 Gram Style: 14\" 2.8K OLED 120Hz, i7-1360P, 16GB LPDDR5, 512GB SSD $1099",
      "finalUrl": "https://www.bhphotovideo.com/c/product/1756921-REG/lg_14z90rs_k_aaw7u1_14_gram_style_laptop.html/BI/865/KBID/1173/SID/d32a5e42891a11ee9ca49e6251c4153f0INT"
    },
    {
      "link": "https://slickdeals.net/f/17069737-2tb-samsung-t7-external-usb-3-2-gen-2-portable-solid-state-drive-gray-100-free-shipping?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Amazon",
      "timestamp": 1700645877985,
      "price": "$100",
      "OGPrice": "$180",
      "upvotes": "23",
      "name": "2TB Samsung T7 External USB 3.2 Gen 2 Portable Solid State Drive (Gray) $100 + Free Shipping",
      "finalUrl": "https://www.amazon.com/gp/product/B0874XWW23/ref=ox_sc_act_title_1?tag=slickdeals09-20&ascsubtag=de5a2126891a11ee80be4267e803f3150INT&smid=ATVPDKIKX0DER&th=1",
      "finalWithReferralTag": "https://www.amazon.com/gp/product/B0874XWW23?tag=mytag123",
      "imageUrl": "https://m.media-amazon.com/images/I/91YfRIy7kYL.__AC_SY300_SX300_QL70_FMwebp_.jpg"
    },
    {
      "link": "https://slickdeals.net/f/17083471-black-friday-deal-save-67-paramount-essential-for-1-99-month-or-paramount-with-showtime-for-3-99-month-for-3-months?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Paramount+",
      "timestamp": 1700645877985,
      "price": "$1.99",
      "OGPrice": null,
      "upvotes": "1",
      "name": "BLACK FRIDAY DEAL! SAVE 67% Paramount+ Essential for $1.99/month or Paramount+ with SHOWTIME for $3.99/month for 3 months",
      "finalUrl": "https://www.paramountplus.com/account/signup/pickplan/?cbsclick=&vndid=10451&clickid=10451&sharedid=&ftag=PPM-09-10aag1f&dclid=CJH018yn14IDFeGVAAAd06IG_g"
    },
    {
      "link": "https://slickdeals.net/f/17075620-costco-asus-14-vivobook-laptop-amd-ryzen-5-7520u-1080p-windows-11-399-97?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Costco Wholesale",
      "timestamp": 1700645877985,
      "price": "$399.97",
      "OGPrice": null,
      "upvotes": "3",
      "name": "Costco - ASUS 14\" Vivobook Laptop - AMD Ryzen 5 7520U - 1080p - Windows 11 - $399.97",
      "finalUrl": "https://slickdeals.net/?sdtrk=jfy&prop=diavail-false%7Cdincp-0%7Cdinpd-0%7Cdipgavail-false%7Crcmid-0a02979deaffc1c549ca5ef912901567&afsrc=1&trd=See%20Deal&sdtid=17075620&tid=17075620&pv=dd26e1b8891a11eeb51636987a0a01ff&au=93d7761f53c84b9592ea914f265cdd36"
    },
    {
      "link": "https://slickdeals.net/f/17066332-asus-rog-flow-z13-13-4-120hz-fhd-i5-12500h-16gb-lpddr5-512gb-ssd-detachable-rgb-keyboard-659-99?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "eBay",
      "timestamp": 1700645877985,
      "price": "$659.99",
      "OGPrice": "$1,750",
      "upvotes": "3",
      "name": "ASUS ROG Flow Z13: 13.4\" 120Hz FHD+, i5-12500H, 16GB LPDDR5, 512GB SSD, Detachable RGB Keyboard $659.99",
      "finalUrl": "https://www.ebay.com/itm/374987175419?mkevt=1&mkcid=1&mkrid=711-53200-19255-0&campid=5337259887&toolid=10001&customid=d35b2810891a11eebee72a70558bfcd60INT"
    },
    {
      "link": "https://slickdeals.net/f/17077345-wood-and-hearts-montessori-climbing-set-of-3-109-6?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "",
      "timestamp": 1700645877985,
      "price": "$109.60",
      "OGPrice": "$274",
      "upvotes": "4",
      "name": "Wood and Hearts Montessori Climbing Set of 3 $109.6",
      "finalUrl": "https://slickdeals.net/?sdtrk=jfy&prop=diavail-false%7Cdincp-0%7Cdinpd-0%7Cdipgavail-false%7Crcmid-0a02979deaffc1c549ca5ef912901567&afsrc=1&trd=See%20Deal&sdtid=17077345&tid=17077345&pv=d2286f66891a11eea15832fabf77f97d&au=93d7761f53c84b9592ea914f265cdd36"
    },
    {
      "link": "https://slickdeals.net/f/17072740-microcenter-intel-i7-14700k-msi-z790-p-pro-wifi-g-skill-32gb-ddr5-6000-kit-computer-build-bundle-499-99?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Micro Center",
      "timestamp": 1700645877985,
      "price": "$500",
      "OGPrice": "$728",
      "upvotes": "52",
      "name": "Combo: Intel Core i7-14700K + MSI Z790-P Pro Motherboard + 32GB G.Skill DDR5-6000",
      "finalUrl": "https://www.microcenter.com/product/5006605/intel-i7-14700k,-msi-z790-p-pro-wifi,-gskill-32gb-ddr5-6000-kit,-computer-build-bundle"
    },
    {
      "link": "https://slickdeals.net/f/17081653-amazon-inaba-churu-cat-treats-grain-free-lickable-squeezable-creamy-pur-e-cat-treat-topper-chicken-varieties-4-67-before-tax?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Amazon",
      "timestamp": 1700645877985,
      "price": null,
      "OGPrice": null,
      "upvotes": "3",
      "name": "Amazon - INABA Churu Cat Treats, Grain-Free, Lickable, Squeezable Creamy Purée Cat Treat/Topper - Chicken varieties 4.67 before tax.",
      "finalUrl": "https://www.amazon.com/gp/product/B07D34KQNW/ref=ppx_od_dt_b_asin_title_s00?tag=slickdeals09-20&ascsubtag=dd9413aa891a11eeab0852d99aef8a320INT&ie=UTF8&th=1",
      "finalWithReferralTag": "https://www.amazon.com/gp/product/B07D34KQNW?tag=mytag123",
      "imageUrl": "https://m.media-amazon.com/images/I/81iMWJJuliL.__AC_SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
      "link": "https://slickdeals.net/f/17078431-apple-magsafe-charger-29?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Amazon",
      "timestamp": 1700645877986,
      "price": "$29",
      "OGPrice": "$39",
      "upvotes": "1",
      "name": "Apple Magsafe Charger $29",
      "finalUrl": "https://www.amazon.com/Apple-MHXH3AM-A-MagSafe-Charger/dp/B08L5NP6NG/ref=sr_1_4?tag=slickdeals09-20&ascsubtag=ddc20fb2891a11eea73c2a34d537341f0INT&crid=YRB1LRNYI8TR&keywords=apple%20magsafe%20charger&qid=1700408071&sprefix=apple%20magsafe%20charger%2Caps%2C89&sr=8-4",
      "finalWithReferralTag": "https://www.amazon.com/Apple-MHXH3AM-A-MagSafe-Charger/dp/B08L5NP6NG?tag=mytag123",
      "imageUrl": "https://m.media-amazon.com/images/I/71f792LZY2L.__AC_SY445_SX342_QL70_FMwebp_.jpg"
    },
    {
      "link": "https://slickdeals.net/f/17082667-costco-apple-watch-gps-series-9-41mm-329-45mm-359-gps-cellular-429-459?src=jfy&prop=rcmid-0a02979deaffc1c549ca5ef912901567",
      "merchant": "Costco Wholesale",
      "timestamp": 1700645877986,
      "price": "from $330",
      "OGPrice": null,
      "upvotes": "30",
      "name": "Costco Members: Apple Watch Series 9 GPS Smartwatch (Various)",
      "finalUrl": "https://www.costco.com/apple-watch-series-9-gps.product.4000062400.html"
    }
  ])
}


export {
  useBackendUpdate,
  getDeals
}